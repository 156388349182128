<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="routerChange(1)">新增优惠券</el-button>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="3" :sm="4" :xs="8">
                    <span class="list-title">优惠券列表</span>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                    <el-table-column label="优惠券名称" min-width="130" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="hover-text-colourful fz-bold" @click="routerChange(3, scope.row)"
                                style="padding-left: 5px">
                                {{scope.row.name}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type == 1 ">满减券</div>
                            <div v-else>折扣券</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠内容" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span v-if="scope.row.price == 0 ">无门槛，</span>
                            <span v-else>满{{scope.row.price / 100}}，</span>
                            <span>减{{scope.row.money / 100}}</span>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column prop="status" label="状态" min-width="120">
                        <template slot-scope="scope">
                            <div class="order-status-div">
                                <div class="order-status-background" :class="scope.row.status | payStatusColorFilter">
                                </div>
                                {{scope.row.status | orderStatusFilter}}
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="send_count" label="已领取/优惠券总量" min-width="120" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="already_send_count" label="已使用" min-width="120"
                        :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="操作" min-width="130" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)">
                                <el-tooltip placement="top" content="编辑">
                                    <i class="iconfont icon-edit" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 20,

            tableData: [],

            editVisible: false,
            editForm: {
                type: '',
                use_type: ''
            }
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        routerChange(key, item) {
            switch (key) {
                case 1:
                    this.$router.push({
                        path: '/online-marketing/discount-edit'
                    });
                    break;
                case 2:
                    this.$router.push({
                        path: '/online-marketing/discount-edit',
                        query: {
                            id: item.id
                        }
                    });
                    break;
                case 3:
                    this.$router.push({
                        path: '/online-marketing/discount-detail',
                        query: {
                            id: item.id
                        }
                    });
                    break;
            }
        },

        getTableData() {
            Core.Api.Coupon.list(
                this.editForm.type,
                this.editForm.use_type,
                this.currentPage,
                this.pageSize
            ).then((res) => {
                console.log('Order.list', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },

        handleEditClose() {
            this.editVisible = false;
            Object.assign(this.$data.editForm, this.$options.data().editForm);
        },

        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Coupon.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.getTableData();
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .dialog-content {
        .content-floor {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            .key {
                display: block;
                width: 120px;
            }
            .value {
                width: 100%;
            }
        }
    }
    .search-box {
        transition: 100ms;
        overflow: hidden;
        .search-div {
            display: flex;
            background: white;
            padding: 25px 14px 0px;
            .search-left {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: calc(100% - 130px);
                .search-block {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;
                    margin-right: 30px;
                    .search-key {
                        width: 100px;
                    }
                    .search-val {
                        > .el-input,
                        > .el-select {
                            width: 200px;
                        }
                    }
                }
            }
            .search-right {
                min-width: 180px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 32px;
                .shrink-btn {
                    min-width: 50px;
                    text-align: center;
                    height: 32px;
                    line-height: 32px;
                    margin-left: 10px;
                    color: #1D517E;
                    cursor: pointer;
                    user-select: none;
                    font-size: 10px;
                    .arrow-top-red {
                        width: 10px;
                        height: 6px;
                        transition: 100ms;
                        margin-left: 3px;
                    }
                    .deg180 {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .color-DC6F7C {
        color: #1D517E;
    }
    .table-container {
        background: white;
        .order-status-div {
            display: flex;
            align-items: center;
            .order-status-background {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: pink;
                margin-right: 3px;
            }
        }
    }
    .background-1890FF {
        background: #1890ff !important;
    }
    .background-52C41A {
        background: #52c41a !important;
    }
    .background-F6BD16 {
        background: #f6bd16 !important;
    }
    .background-BBBBBB {
        background: #bbbbbb !important;
    }
    .icon-Alipay {
        color: #06b4fd;
    }
    .icon-WeChat {
        color: #09bb07;
    }
}
</style>